import {appInfo} from 'constData'
import {LANDING_SITE_URL} from 'config'
import {formatDate, formatNumber, makePersonalizeReferralLink} from 'utils'

export const apiDateFormat = item => ({
  ...item,
  transfer: item?.transfer
    ? transferFromat(item?.transfer)
    : item?.status === 'confirmed' && item?.payout_wallet === 'Platform Wallet'
    ? {status: 'completed'}
    : null,
  created_at: formatDate(item?.created_at),
  updated_at: formatDate(item?.updated_at),
})

export const paymentFromat = item => ({
  ...item,
  payment_for: item?.payment_for?.split('_').join(' '),
  payment_type: item?.payment_type?.split('_').join(' '),
  formated_received_amount:
    item?.received_amount_number &&
    item?.status === 'confirmed' &&
    item?.payout_wallet === 'Platform Wallet'
      ? formatNumber(item?.received_amount_number, {minimumFractionDigits: 4}) +
        ' ' +
        item?.received_amount_currency
      : item?.transfer
      ? formatNumber(item?.transfer?.amount_number, {minimumFractionDigits: 4}) +
        ' ' +
        item?.transfer?.amount_currency
      : null,

  formated_paid_amount: item?.paid_amount_number
    ? formatNumber(item?.paid_amount_number, {minimumFractionDigits: 2}) +
      ' ' +
      item?.paid_amount_currency
    : null,

  formated_payout_amount: item?.payout_amount
    ? formatNumber(item?.payout_amount, {minimumFractionDigits: 2}) + ' ' + item?.target_asset
    : null,

  transfer: item?.transfer
    ? transferFromat(item?.transfer)
    : item?.status === 'confirmed' && item?.payout_wallet === 'Platform Wallet'
    ? {status: 'completed'}
    : null,
})

export const transferFromat = item => ({
  ...item,
  formated_received_amount: item?.amount_number
    ? formatNumber(item?.amount_number, {minimumFractionDigits: 4}) + ' ' + item?.amount_currency
    : null,
})

export const formatEstimatePay = object => ({
  ...object,
  received_amount: parseFloat(object?.received_amount).toFixed(4),
  total_amount: parseFloat(object?.total_amount).toFixed(2),
  dynamic_fee_amount: parseFloat(object?.dynamic_fee_amount).toFixed(4),
})

export const referralStatsDateFormat = item => ({
  ...item,
  referral_joined_at: formatDate(item?.referral_joined_at),
  total_amount_formated: item?.total_amount
    ? formatNumber(item?.total_amount, {minimumFractionDigits: 1})
    : null,
  referral_total_purchase_formated: item?.referral_total_purchase
    ? formatNumber(item?.referral_total_purchase, {minimumFractionDigits: 1})
    : null,
  referral_total_sale_formated: item?.referral_total_sale
    ? formatNumber(item?.referral_total_sale, {minimumFractionDigits: 1})
    : null,
  referral_total_qv_formated: item?.referral_total_qv
    ? formatNumber(item?.referral_total_qv, {minimumFractionDigits: 1})
    : null,
})

export const exportDataFormat = item => ({
  ...item,
  referrer_token: makePersonalizeReferralLink(LANDING_SITE_URL, item?.referrer_token, false),
})

export const commissionsFormat = item => ({
  ...item,
  commission_type: item?.commission_type?.split('_').join(' '),
  formatted_amount: item?.amount
    ? formatNumber(item?.amount, {minimumFractionDigits: 4, maximumFractionDigits: 4}) +
      ' ' +
      appInfo.apiId
    : null,
})
