const cacheKeys = {
  profile: 'profile',
  balanceTotal: 'balanceTotal',
  asset: 'asset',
  portfolioChart: 'portfolioChart',
  dashboardChart: 'dashboardChart',
  commissionsChart: 'commissionsChart',
  prices: 'prices',
  assetPriceChange: keys => ['assetPriceChange', {...keys}],
  assetsPrices: 'assetsPrices',
  assetsRates: 'assetsRates',
  quotesCharts: 'quotesCharts',
  marketFees: 'marketFees',
  mfaSecret: 'mfaSecret',
  bonusesTable: 'bonusesTable',
  bonusesInfo: 'bonusesInfo',
  configVars: 'configVars',
  primaryMarketTotalBought: 'primaryMarketTotalBought',
  notifications: 'notifications',
  notificationsUnreadCount: 'notificationsUnreadCount',
  tradeHistoryPayments: 'tradeHistoryPayments',
  orderDetails: 'orderDetails',
  tradeHistoryOrders: 'tradeHistoryOrders',
  affiliateCommission: 'affiliateCommission',
  affiliateRankStats: 'affiliateRankStats',
  affiliateStats: 'affiliateStats',
  userDetailsInfo: 'userDetailsInfo',
  checkPaymentService: 'checkPaymentService',
  bankDetails: 'bankDetails',
  surveyStatus: 'surveyStatus',
  bankPdf: 'bankPdf',
  goldCardPackage: 'goldCardPackage',
  gvLineChart: 'gvLineChart',
  monthlyStatistics: 'monthlyStatistics',
  poolChart: 'poolChart',
  statisticsDetails: 'statisticsDetails',
  // csvData: 'csvData',
  featureFlag: 'featureFlag',

  // fees
  dynamicFees: 'dynamicFees',

  // api settings
  apiKeys: 'apiKeys',
  apiKeySecret: id => ['apiKeySecret', id],
  webhooks: 'webhooks',
  webhookInstance: id => ['webhookInstance', id],
  webhookDeliveries: 'webhookDeliveries',

  maintenanceMode: 'maintenanceMode',
}

export default cacheKeys
