import autoBind from 'auto-bind'

import {
  apiDateFormat,
  referralStatsDateFormat,
  paymentFromat,
  transferFromat,
  exportDataFormat,
  commissionsFormat,
} from 'utils/formatApi'
import {SERVER_REGION} from 'config'
import {formatDate, formatNumber} from 'utils'

import ApiBase from './ApiBase'

export default class ApiMethods extends ApiBase {
  constructor(props) {
    super(props)
    autoBind(this)
  }

  // AUTH API
  async login({mfa_code, ...userProps}) {
    const {data, headers} = await this.post(
      '/auth/login',
      {
        user: userProps,
        mfa_code,
      },
      true
    )
    return {
      user: data?.user,
      token: headers.authorization,
    }
  }

  async signUpInitial(params) {
    const {data, headers} = await this.post(
      '/auth/signup',
      {
        user: params,
      },
      true
    )
    return {
      user: data?.user,
      token: headers.authorization,
    }
  }

  async checkReferral(params) {
    return await this.get('/users/check_referral', params)
  }

  async resendEmailConfirmation({email}) {
    return this.post('/auth/confirmation', {
      user: {
        email,
      },
    })
  }

  async emailConfirm({token}) {
    const {data, headers} = await this.get(
      '/auth/confirmation',
      {
        confirmation_token: token,
      },
      true,
      {}
    )
    return {
      user: data.user,
      token: headers.authorization,
    }
  }

  async additionalInformation({id, ...params}) {
    return this.put(`/users/${id}`, {
      user: {
        user_detail_attributes: params,
      },
    })
  }

  async passwordResetRequest({email}) {
    return this.post('/auth/password', {
      user: {
        email,
      },
    })
  }

  async passwordResetConfirm({token: reset_password_token, password, password_confirmation}) {
    return this.put('/auth/password', {
      user: {
        reset_password_token,
        password,
        password_confirmation,
      },
    })
  }

  async changePassword({mfa_code, ...userProps}) {
    return this.put('/auth/signup', {
      mfa_code,
      user: userProps,
    })
  }

  async changeEmailRequest({uid, email, mfa_code}) {
    return this.put(`/users/${uid}`, {
      mfa_code,
      user: {
        email,
      },
    })
  }

  async createNewMfa() {
    return this.get('/auth/google_mfa/new')
  }

  async proceedMfa({activation, mfa_code}) {
    return this.post('/auth/google_mfa', {
      user_mfa_session: {activation, mfa_code},
    })
  }

  async logout() {
    return this.delete('/auth/logout')
  }

  async profile() {
    const {user} = await this.get('/auth/profile')
    return user
  }

  async getUserInfo(id) {
    const {user} = await this.get(`/users/${id}`)
    return user
  }

  async deleteUser({id, params}) {
    return await this.post(`/users/${id}/request_deletion`, params)
  }

  async enableAffiliate({id}) {
    return await this.post(`/users/${id}/enable_affiliate`)
  }

  async getUserAffiliateCommission({id}) {
    const {commissions_account} = await this.get(`/users/${id}/commissions_account`)
    return commissions_account
  }
  async getUserAffiliateRankStats({id}) {
    return await this.get(`/users/${id}/affiliate_rank_stats`)
  }

  async getReferralStats({id, ...params}) {
    const {referrals_stats, meta, referrer_name, previous_referrer_id} = await this.get(
      `/users/${id}/referrals_stats`,
      params
    )

    return {
      data: referrals_stats?.map(referralStatsDateFormat),
      meta: meta,
      referrer_name,
      previous_referrer_id,
    }
  }

  async getCsvData(id) {
    const {referrals_stats} = await this.get(`users/${id}/export_whole_downlines`)

    return {
      referrals_stats: referrals_stats?.map(exportDataFormat),
    }
  }

  async bwgWithdraw(params) {
    return await this.post('/custodial_transfers', params)
  }

  async bankInformation({id, ...params}) {
    return await this.get(`users/${id}/bank_information`, params)
  }

  async commissionPayout(params) {
    return await this.post('/payouts', params)
  }

  async walletPersistence({id, ...rest}) {
    return await this.post(`/users/${id}/wallet`, {...rest})
  }

  async walletChangeWithToken({token, ...rest}) {
    return await this.get('users/confirm_wallet_changing', {token, ...rest})
  }

  // fees
  async getDynamicFees() {
    const {dynamic_fees} = await this.get(`/dynamic_fees`)
    return dynamic_fees
  }

  // charts
  async getPortfolioChart({symbol, days, ...rest}) {
    let points = days * 24 * 60
    return await this.get(`/assets/${symbol}/portfolio_chart`, {
      period: days,
      points,
      ...rest,
    })
  }

  async getChartSymbol({symbol, days, currency, ...rest}) {
    let points = days * 24 * 60
    return await this.get(`/assets/${symbol}/chart`, {
      period: days,
      points,
      vs_currency: currency,
      ...rest,
    })
  }

  async commissionsChartData(params) {
    const data = await this.get('/commissions/chart', params)

    return (
      data &&
      data.map(item => ({
        ...item,
        data: item.data.map(item => ({
          x: formatDate(item.date, 'D MMM'),
          y: item.amount,
        })),
      }))
    )
  }

  async getTransferChart(params) {
    return await this.get('transfers/marimekko_chart', params)
  }

  async getPaymentsChart(params) {
    return await this.get('payments/marimekko_chart', params)
  }

  async getAssets() {
    const {assets} = await this.get('/assets')
    return assets
  }

  async getAssetBySymbol(symbol, params) {
    const {asset} = await this.get(`/assets/${symbol}`, params)
    return asset
  }

  async getAssetPriceChange({symbol, ...params}) {
    return await this.get(`/assets/${symbol}/price_change`, params)
  }

  async getAssetsPricesLatest({currency}) {
    return await this.get(`/assets/latest?vs_currency=${currency}`)
  }

  // payments API
  async getEstimateFee({...params}) {
    return await this.get('/payments/estimate_fee', params)
  }

  async getRates({...params}) {
    return await this.get('/payments/rates', params)
  }

  async getCryptoCurrentPayment() {
    const {payment} = await this.get('/payments/current')
    return apiDateFormat(payment)
  }

  async checkPaymentService() {
    return await this.get('/payments/status')
  }

  async makePayment(params) {
    return await this.post('/payments', params)
  }

  async getOrders(params) {
    const {payments, meta} = await this.get('/payments', params)
    return {
      data: payments
        .map(apiDateFormat)
        .map(item => ({
          ...item,
          payment_for: item?.payment_for === 'gold_card' ? 'package' : item?.payment_for,
        }))
        .map(paymentFromat),
      meta: meta,
    }
  }

  async getOrder(id) {
    const {payment} = await this.get(`/payments/${id}`)
    return paymentFromat(apiDateFormat(payment))
  }

  async getBankDetails(id) {
    return this.get(`/payments/${id}/bank_details`, {region: SERVER_REGION})
  }

  async getInvoicePdf(id) {
    return this.get(`/payments/${id}/bank_invoice`, {region: SERVER_REGION})
  }

  async getTransfer(id) {
    const {transfer} = await this.get(`/transfers/${id}`)
    return transferFromat(apiDateFormat(transfer))
  }

  async getAllTransfer(params) {
    const {transfers, meta} = await this.get('/transfers', params)
    return {
      data: transfers.map(apiDateFormat).map(transferFromat),
      meta: meta,
    }
  }

  async getConfigVariable(name) {
    const {configuration_variable} = await this.get(`/configuration_variables/${name}`)
    return configuration_variable
  }

  async getNotifications(params) {
    return this.get('/notifications', {
      sort_by: 'deliver_at',
      sort_order: 'desc',
      ...params,
    })
  }

  async getUnreadNotificationsCount() {
    const {notifications} = await this.get('/notifications', {
      is_read: 0,
    })
    return notifications.length
  }

  async notificationRead({id}) {
    const {notification} = await this.post(`/notifications/${id}/make_read`)
    return notification
  }

  // eslint-disable-next-line class-methods-use-this
  async isCryptoApiWorking() {
    await new Promise(r => setTimeout(r, 150))
    return true
  }

  async submitContactUsForm(data) {
    return this.post(`/contact_us/submit`, data)
  }

  async emailUnsubscribe(userId) {
    return this.post(`/users/${userId}/unsubscribe`).then(res => res.user)
  }

  async emailSubscribe(userId) {
    return this.post(`/users/${userId}/subscribe`).then(res => res.user)
  }

  async getMaintenanceStatus() {
    return this.get('/maintenance_mode')
  }

  async surveySubmit({id, ...params}) {
    return this.post(`/users/${id}/survey`, params)
  }

  async checkSurveyStatus({id, ...params}) {
    return this.get(`/users/${id}/survey_status`, params)
  }

  async getGoldCardPackage() {
    const {gold_card_packages} = await this.get(`/gold_card_packages`)
    return gold_card_packages
  }

  async getGoldCardAccount() {
    const {gold_card_accounts} = await this.get(`/gold_card_accounts`)
    return gold_card_accounts[0]
  }

  async purchaseGoldCardPackages({id, asset, type}) {
    return this.post(`/gold_card_packages/${id}/purchase`, {asset, type})
  }

  async getAllPayouts(params) {
    const {payouts, meta} = await this.get('/payouts', params)
    return {
      data: payouts.map(apiDateFormat).map(paymentFromat),
      meta: meta,
    }
  }

  async bwgWithdrawHistory(params) {
    const {custodial_transfers, meta} = await this.get('/custodial_transfers', params)
    return {
      data: custodial_transfers.map(apiDateFormat).map(paymentFromat),
      meta: meta,
    }
  }

  async commissionsHistory({id, ...params}) {
    const {commissions, meta} = await this.get(`/users/${id}/commission_history`, params)
    return {
      data: commissions.map(apiDateFormat).map(commissionsFormat),
      meta: meta,
    }
  }

  async gvLineChart(id) {
    return await this.get(`/users/${id}/top_three_line_qv`)
  }

  async monthlyStatistics() {
    const {commission_statistics} = await this.get('commissions/monthly_commission_statistics')
    return commission_statistics
  }

  async poolChart() {
    return await this.get('pools/pool_balance')
  }

  async statisticsDetails(params) {
    const {commissions, ...rest} = await this.get('commissions/monthly_statistics_details', params)
    return {
      data: commissions.map(apiDateFormat).map(commissionsFormat),
      ...rest,
    }
  }

  async gvDetails({userId, ...params}) {
    const {qv_histories, ...rest} = await this.get(`/users/${userId}/total_qv_line`, params)
    return {
      data: qv_histories.map(apiDateFormat).map(item => ({
        ...item,
        formatted_amount: item?.amount
          ? `${formatNumber(item?.amount, {minimumFractionDigits: 4, maximumFractionDigits: 4})} QV`
          : null,
      })),
      ...rest,
    }
  }

  async featureFlag({id, ...params}) {
    return await this.get(`users/${id}/check_feature_flag`, params)
  }
}
