import {useQuery} from '@tanstack/react-query'

import {useApi, useProfile} from 'hooks'
import {cacheKeys} from 'api'

const useFeatureFlag = flagName => {
  const api = useApi()
  const {profile} = useProfile()

  const {data, ...rest} = useQuery({
    queryKey: cacheKeys.featureFlag,
    queryFn: () => api.featureFlag({id: profile?.id, flag_name: flagName}),
  })
  return {
    isEnabled: data,
    ...rest,
  }
}

export default useFeatureFlag
